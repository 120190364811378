import 'bootstrap/dist/css/bootstrap.min.css';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";
  import { Button,Row,Container,Navbar,Nav,NavDropdown,Offcanvas,Form,FormControl,InputGroup,Col} from 'react-bootstrap';
  import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faSearch } from '@fortawesome/free-solid-svg-icons'
import {Sendreq} from '../req'
import Topgameslider from '../helpers/topgameslider'
import SearchResComp from '../helpers/SearchRes'
export default function (props){
  const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
  const [Topfivearray,SetTopfivearray]=useState([]);
  const [SearchRes,SetSearchRes]=useState([])
  const [SearchForm,SetSearchForm]=useState("")
  const [displaySerch,SetdisplaySerch]=useState("none")
  const [GenerList,SetGenerList]=useState([])
  let history = useHistory();

  useEffect(async()=>{
    
    const topfive=await Sendreq(REACT_APP_API_URL+'apicall/Topfive',{});
    SetTopfivearray(topfive.data)
    const Genre=await Sendreq(REACT_APP_API_URL+'apicall/Genre',{req:"asd"});
    SetGenerList([{id: 0, mainid: 0, genrename: 'All'},...Genre.data])


  },[])

  useEffect(async()=>{
    if(SearchForm.length>=3){
      const SearchApi=await Sendreq(REACT_APP_API_URL+'apicall/Search',{Search:SearchForm,Limit:5});
      console.log(SearchApi)

      SetSearchRes(SearchApi.data);
      SetdisplaySerch("block");


    }

  },[SearchForm])

  function logoclick(){
    history.push("/");

  }
  function SearchReq(){

 
    
  }
  function CloseSearch(){

    setTimeout(() => {
      SetSearchRes([]);
      SetdisplaySerch("none");
      SetSearchForm("")
      
    }, 200);
 


  }


  return(
    <>
    <Navbar bg="" expand="lg">
  <Container>
    <Navbar.Brand href="#">COMPKEYS</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
        <Nav.Link><Link to="/">Home Page</Link></Nav.Link>
        <Nav.Link ><Link to="/games/All">Games</Link></Nav.Link>
        <Nav.Link >
          <Link to="/shops">Shops</Link>
          
        </Nav.Link>



        <NavDropdown title="Genres" id="navbarScrollingDropdown">


          {GenerList.map((item,index)=>
          
            <NavDropdown.Item><Link to={"/games/"+item.genrename}>{item.genrename}</Link></NavDropdown.Item>
            
           
          
          )}
          
        
{/*           <NavDropdown.Divider /> */}
       
        </NavDropdown>

        <Nav.Link href="/page/about">
          About us
        </Nav.Link>
        <Nav.Link href="#">
          Contact
        </Nav.Link>
       
      </Nav>
      <Form className="d-flex relative">
      <FontAwesomeIcon className='SearchIcon' icon={faSearch} />
        <FormControl
          type="search"
          placeholder="Type your Game search" 
          className='headerSearch'
          aria-label="Search"
          value={SearchForm}
          onChange={(e)=>{SetSearchForm(e.target.value)}}
          onBlur={CloseSearch}  


        />
          <div style={{display:displaySerch}} className='formSearchRes'>
      {SearchRes && SearchRes.map((item,index)=>
      
    
        <SearchResComp {...item} SearchForm={SearchForm}/> 

      )}
      <Link className='ShowAllResults' to={"/search/"+SearchForm}><p>Show All Results</p></Link>
      
      </div> 
      
      </Form>
    </Navbar.Collapse>
  </Container>
</Navbar>
    </>
  );


   // return(
      
      {/*   <Navbar bg="" expand={false}>

        <Form.Group className='Form-Search mobile' >
    <FontAwesomeIcon className='SearchIcon' icon={faSearch} />
    <Form.Control
     type="text"
      className='SearchForm' 
      placeholder="Type your search" 
       onBlur={CloseSearch}  
      value={SearchForm}
      onChange={(e)=>{SetSearchForm(e.target.value)}}
      
      />
      <div style={{display:displaySerch}} className='formSearchRes'>
      {SearchRes && SearchRes.map((item,index)=>
      
    
        <SearchResComp {...item} /> 

      )}
      
      </div> 
  
    

  </Form.Group>
  <Container>
  <Navbar.Brand onClick={logoclick}><img className='logo' src="/logo.png"></img></Navbar.Brand>

    
  
   
    
    <Form.Group className='Form-Search des' >
    <FontAwesomeIcon className='SearchIcon' icon={faSearch} />
    <Form.Control
     type="text"
      className='SearchForm' 
      placeholder="Type your search" 
       onBlur={CloseSearch}  
      value={SearchForm}
      onChange={(e)=>{SetSearchForm(e.target.value)}}
      
      />
      <div style={{display:displaySerch}} className='formSearchRes'>
      {SearchRes && SearchRes.map((item,index)=>
      
    
        <SearchResComp {...item} /> 

      )}
      
      </div> 
  
    

  </Form.Group>
  
 
   
  
    
    <Navbar.Toggle aria-controls="offcanvasNavbar" />
   

    <Navbar.Offcanvas
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="end"
    >

      
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="offcanvasNavbarLabel">Offcanvas</Offcanvas.Title>


      </Offcanvas.Header>



      <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <Nav.Link href="#action1">Home</Nav.Link>
          <Nav.Link href="#action2">Link</Nav.Link>
          <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              Something else here
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Form className="d-flex">
          <FormControl
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
          />
          <Button variant="outline-success">Search</Button>
        </Form>
      </Offcanvas.Body>


    </Navbar.Offcanvas>
  </Container>
</Navbar>

 //*/}

   // );

}