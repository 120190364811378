import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {Sendreq} from '../../req'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";
  import ThreeGameList from './ThreeGameList';
  import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {isMobile} from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import MoblieSlidersHomePage from '../HomePageMoblieSlider'
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";


export default function (props){
    const [GameList,SetGameList]=useState([])
    const [Loading,SetLoading]=useState(true);
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const {choose}=props
    const baseurl=String(REACT_APP_BASE_FOR_IMAGES);

useEffect(async ()=>{
  SetLoading(true)
  var GetViews=null;
  if(choose===1){
     GetViews=await Sendreq(REACT_APP_API_URL+'apicall/MostView',{});
  }
  if(choose===2){
    GetViews=await Sendreq(REACT_APP_API_URL+'apicall/Newest',{Limit:5});
    //console.log(GetViews)
    
  }
  if(choose===3){
    GetViews=await Sendreq(REACT_APP_API_URL+'apicall/upcoming',{Limit:5});

  }
    
    SetGameList(GetViews.data)
    SetLoading(false)

},[choose])


const LoadingFN=()=>{
  return (
    <>
    <Col md={2}>
      <Skeleton height={250} />
      <Skeleton height={25} />
    </Col>
      <Col md={2}>
      <Skeleton height={250} />
      <Skeleton height={25} />
    </Col>
      <Col md={2}>
      <Skeleton height={250} />
      <Skeleton height={25} />
    </Col>
    </>

  )
}

if(isMobile){
  return(
 
      <Swiper
        spaceBetween={15}
        slidesPerView={3}
        pagination={{
          type: "progressbar",
        }}
        watchSlidesVisibility={true}
      
        freeMode={true}
       
        modules={[Pagination, Navigation]}
     
      
      >
        {GameList.map((item,index)=>

        
        <SwiperSlide>
        <Link to={"game/"+item.Gameid+"/"+typeof item.GameNameOFFICAL === "undefined" ? item.GameNameDEFAULT.replace(/[^a-z0-9]/gi, '-').toLowerCase() : item.GameNameOFFICAL.replace(/[^a-z0-9]/gi, '-').toLowerCase()}>
        <div className='SliderProduct'>
          <picture>
            <img src={baseurl+item.coverimage}>
            

            </img>
         
            </picture>
            <div className='SliderProduct-text'>
              <span>{item.GameNameOFFICAL}</span>
            </div>
        
            <div className='SliderProduct-price'>
            {item.MinPrice}$
            </div>
        </div>
        </Link>
     </SwiperSlide>
        )}
      
    
     
      </Swiper>
   
  )
}

const DisaplayList=()=>{
  return (
    <>
  {GameList && GameList.map((item,index)=>
    <ThreeGameList key={Math.floor(Math.random() * 2000000)} {...item} />


    
    
    )}
    </>
  )

}


   return(
   <>
   <Row>
     {Loading ? <LoadingFN /> : <DisaplayList />}

   </Row>
   </>
   
    
   );

    

}