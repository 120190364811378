import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Form} from 'react-bootstrap';
import { useState,useEffect } from 'react';





export default function (props){
    const {Usingplatform,County,SetRegionChooseFN,SetPlatformChooseFN}=props;
    

    return(
        <div className="d-flex align-items-center justify-content-end">
        <div className="p-2"><span>Filter:</span></div>
        <div className="p-2">
            <Form.Select onChange={(e)=>{SetPlatformChooseFN(e.target.value)}}>
    <option  value={"0"}>Select Platform</option>
    {Usingplatform.map((item,index)=>
        <option value={item}>{item}</option>

    )}
  </Form.Select>
  </div>
        <div className="p-2">
        <Form.Select onChange={(e)=>{SetRegionChooseFN(e.target.value)}}>
    <option value={"0"} >Select Region</option>
    {County.map((item,index)=>
    <option value={item}>{item}</option>
    
    
    )}
  </Form.Select>
        </div>

        </div>
    );

}