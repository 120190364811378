import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';

export default function (props){
    const {type}=props;

return(
    <div className='Browserslider'>
        <h1>{type} Games!</h1>
   

    </div>

);

}