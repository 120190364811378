import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";

export default function (props){

   return(
       
       <div className='AboutSession'>
       <Container>
        <Row className='CenterFlex'>
            <Col md={8}>
                <div className='AboutContect'>
               <h2>What is CompKeys ?</h2> 

Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and

<h2>How do i benefite from that ?</h2>

scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release

<h2>cd key or actual copy ?</h2>

scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release

&nbsp;


                </div>
            </Col>
        </Row>   
        </Container>    

       </div>
   
   

     
  

   );

    

}