import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect,useMemo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useParams
    
  } from "react-router-dom";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCoffee,faSearch,faBomb,faCrosshairs,faFutbol,faChessRook,faBolt} from '@fortawesome/free-solid-svg-icons'
  import {Sendreq} from '../req'
  import ThreeGameList from '../helpers/HomePageFirstGames/ThreeGameList';



export default function (props){
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const [GamesobjList,SetGamesobjList]=useState([])

    let { query } = useParams();
    useEffect(async()=>{
        
          const SearchApi=await Sendreq(REACT_APP_API_URL+'apicall/Search',{Search:query,Limit:15});
       
          SetGamesobjList(SearchApi.data)
    
       
    
    
      
    
      },[])
  








    return(
        <div className='SearchResultsPage'>
            <Container>



        <h1>Show Search Results for : <label>{query}</label></h1>

        <div className='SearchResultsContain'>

        <Row>
    
    
        {GamesobjList && GamesobjList.map((item,index)=>
    <ThreeGameList key={Math.floor(Math.random() * 2000000)} {...item} />
    

    
    
    )}
   
 
    </Row>
    </div>
    

        </Container>

        </div>
       
 
    );

}