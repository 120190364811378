import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,Tooltip,Col,Form} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation,
    useParams 
  } from "react-router-dom";

  import Category from '../helpers/GameCategorys'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCoffee,faSearch,faBomb } from '@fortawesome/free-solid-svg-icons'
  import {Sendreq} from '../req'
  import HomePageHeder from '../helpers/HomePageHeder'
  import Select from '../helpers/ProductPage/Selects'
  import {Helmet} from "react-helmet";

export default function (props){
    let { gameid } = useParams();
    
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const [Categorey,SetCategorey]=useState([]);
    const [gamedata,Setgamedata]=useState([]);
    const [ShopObj,SetShopObj]=useState([]);
    const [Shops,SetShops]=useState([]);
    const [SliderTop,SetSliderTop]=useState("/static/media/slider.882632342735dcfafc5f.png");
    const baseurl=String(REACT_APP_BASE_FOR_IMAGES);
    const [Load,SetLoad]=useState(5);
    const [County,SetCounty]=useState([]);
    const [Usingplatform,SetUsingplatform]=useState([]);
    const [RegionChoose,SetRegionChoose]=useState("0");
    const [PlatformChoose,SetPlatformChoose]=useState("0");
    const [ListBackUp,SetListBackUp]=useState([]);
    const [ScreenShots,SetScreenShots]=useState([]);
    var basictitle=' | Cd Key Compare Prices';


    function ResetListRegion(){
        SetShops(ListBackUp.filter(CheckPlatform))
        SetShopObj(ListBackUp.filter(CheckPlatform))
    }

    function ResetListPlatform(){
        SetShops(ListBackUp.filter(CheckRegion))
        SetShopObj(ListBackUp.filter(CheckRegion))
    }


    function CheckPlatform(item){
        if(PlatformChoose==="0")
            return true;

        return item.usingplatform===PlatformChoose;
            
        
    }

    function CheckRegion(item){
        if(RegionChoose==="0")
            return true;
        return item.productregion===RegionChoose;
            

    }

    function Filters(item){

        if(CheckPlatform(item)===true && CheckRegion(item)===true){
            return true;
        }

        return false;

    }


    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      function SetRegionChooseFN(val){
        SetRegionChoose(val);

      }

      function SetPlatformChooseFN(val){
        SetPlatformChoose(val);
        
    }






    function FilterWhat(){
   
        //console.log("Filter Platform : " +PlatformChoose +" And Region : "+RegionChoose  )


    }



    useEffect(()=>{
        FilterWhat()
        SetShops(ListBackUp.filter(Filters))

        
    },[RegionChoose])

    useEffect(()=>{
        FilterWhat()
        SetShops(ListBackUp.filter(Filters))
      
    },[PlatformChoose])
      

    
    useEffect(async()=>{
        const gamelist=await Sendreq(REACT_APP_API_URL+'apicall/getgame',{gameid:gameid});
       
        var obj1=await gamelist.data[0];
        ///var basictitle='| Cd Key Compare Prices';
        
      ///  document.title=obj1.officialtitle !== null ? obj1.officialtitle+' ' +basictitle : obj1.defaulttitle+' ' +basictitle
       
        
        Setgamedata({coverimage:obj1.coverimage!==null ? obj1.coverimage : obj1.defaultcoverimage ,officialtitle:obj1.officialtitle,screenshot:obj1.screenshot,id:obj1.id,releasedate:obj1.releasedate,shopid:obj1.shopid,shopname:obj1.shopname,sitelogofull:obj1.sitelogofull});

        SetShops(gamelist.data/* .slice(0, Load) */);
        SetShopObj(gamelist.data)
        SetListBackUp(gamelist.data)
       

        
        if(gamelist.data[0].screenshot !==null){
            let json=JSON.parse(gamelist.data[0].screenshot)
            SetSliderTop(json[0]);
            SetScreenShots(json.slice(1, 4));
       
        }
        
    
     let PlatformTemp=[];
     let RegionTemp=[];
     let obj=gamelist.data;


     for(var i=0;i<obj.length;i++){
        PlatformTemp.push(obj[i].usingplatform);
        RegionTemp.push(obj[i].productregion);
     }
     SetCounty(RegionTemp.filter(onlyUnique))
     SetUsingplatform(PlatformTemp.filter(onlyUnique))



    },[])

    const style={
        backgroundImage: `url(${baseurl+SliderTop})`
    }


useEffect(()=>{
    if(Load>6){
        SetShops(ShopObj.slice(0, Load))

    }

  



},[Load])
function LoadMoreFN(){
    SetLoad(Load+3);
  

}

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
  
    var time = date + ' ' + month + ' ' + year + ' ';

    return time;
  }

  function ShowMoreBool(index){
    if(index===0 && Shops.length>1){
        return true;
    }
    return false

  }


    return(
        <>
        <Helmet>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={gamedata.officialtitle !== null ? gamedata.officialtitle +basictitle : gamedata.defaulttitle +basictitle} />
            <meta property="og:image" content={baseurl+gamedata.coverimage} />
            <title>{gamedata.officialtitle !== null ? gamedata.officialtitle +basictitle : gamedata.defaulttitle +basictitle }</title>
        
        </Helmet>
        <div style={style} className='headerProduct'>
            <div className='insideProductHeader'>
                <div className='headerProductContect'>
                    <Container>
                        <Row>
                    <h5>Buy {gamedata.officialtitle} CdKey Price Compere</h5>
                        <Col md={3}>
                            <picture className='ProductPic'>
                                <img  src={baseurl+gamedata.coverimage} className='img-fluid' />
                            </picture>
 

                        </Col>

                            <Col md={6}>
                                <div className='ProductTitle'>
                                <h1>{gamedata.officialtitle}</h1>
                                <h3>{timeConverter(gamedata.releasedate)}</h3>


                                </div>
                        
                            </Col>
                        </Row>

                    </Container>

                </div>


            </div>

        </div>
        <div className='ProductSingle'>
        <Container>

            
            <Row  /* className='CenterFlex' */  >
               
                
              
                
                 <Col md={9}>
                 <Select className="Filtes" SetRegionChooseFN={SetRegionChooseFN} SetPlatformChooseFN={SetPlatformChooseFN} Usingplatform={Usingplatform} County={County}/>
                    <div className='PricesContect'>
                        <div className='ShopCompression'>
                        <Row>

                        <Col md={2} xs={2}>
                            <p>Shop</p>
                        </Col>
                        <Col md={5} xs={5}>
                            <p>Game Title</p>
                        </Col>

                        <Col md={1} xs={1}>
                            <p>Platform</p>
                        </Col>
                        <Col md={2} xs={2}>
                            <p>Region</p>
                        </Col>
                        <Col md={2} xs={2}>
                            <p>Price</p>
                        </Col>


                        </Row>
                        </div>
                   
                        {Shops.map((item,index)=>
                        <>
                        <Link to={"/redirect/"+item.id} target="_blank">
                         
                        <Row className={index===0 ? "SingleProductRow CheapestOffer" : "SingleProductRow" }>
              
                        {index===0 && <div className='CheapestMessage'>
                            <span>Our Cheapes!</span>
                        </div>}
                         <Col md={12} xs={12}>
                         <Row className='CenterAlign'>


                         </Row>

                             <Row className='CenterAlign'>

                             <Col md={2} className="imgcol" xs={2}>
                                 <div className='ShopBrandSingleProduct'>
                                 <picture>
                                 <img className='img-fluid' src={baseurl+item.sitelogofull} />
                                 </picture>
                                 </div>
                             </Col>
                             <Col md={5} xs={5}>
                             <div className='ShopBrandSingleProduct'>
                             <span>{item.platformtitle }</span>
                             </div>
                             </Col>
                  
                                <Col md={1} className="imgcol" xs={1}>
                              
                                    
                          
                                    <img className='img-fluid' src={baseurl+"//images/platformlogo/"+item.usingplatform.toLowerCase().replace(/\s/g, '')+".png"} />
                                  
                                </Col>
                                <Col md={2} xs={2}>
                                <div className='ShopBrandSingleProduct'>
                                <span>{item.productregion}</span>
                                </div>
                                </Col>
                                <Col md={2} xs={2}>
                                <div className='ShopBrandSingleProduct'>
                                    
                                <span>${item.productprice}</span>
                                </div>
                         
                                </Col>
                            </Row>
                            </Col>
                            </Row>
                            </Link>

                            {ShowMoreBool(index) && <h2 className='MoreOffersH2'>More Offers</h2> }
                            
                            </>
                        )}
                    {ShopObj.length>=6 && <Button onClick={LoadMoreFN} className='LoadMorebtn' variant="outline-primary">Load More</Button>}
                    </div>
                </Col>

                <Col md={3}>
                    <div className='ScreenShot'>
                        <h1>Screenshot</h1>

                    {ScreenShots.map((item,index)=>
                            <img src={baseurl+item} />
                        
                        )}


                    </div>

                
                </Col>




            </Row>
            
            </Container>

            
        </div>
        </>
    );

}