/* import logo from './logo.svg'; */
import './App.css';
import { useState,useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import HomePage from './pages/homePage'
import Header from './pages/Header';
import ProductPage from './pages/ProductPage'
import Footer from './pages/Footer';
import SingleShop from './helpers/GameShopSession/SingleShop'
import RedirectLink from './helpers/Redirect/RedirectLink'
import Search from './pages/Search';
import Games from './pages/BrowsGames'
import Errorpage from './pages/404'
import TextPage from './pages/TextPage'
import ShopsPage from './pages/Shops'


function App() {
  return (
  
 
    <div className="App">
  

    <Router>
    <header className="App-header">

<Header />

</header>
      <Switch>
    

      <Route exact path="/" component={HomePage} >

      </Route>

      <Route exact path="/game/:gameid/:gamename?" component={ProductPage} >

      </Route>


    <Route exact path="/shop/:shopid/:shopname" component={SingleShop} >

    </Route>


     <Route exact path="/redirect/:linkid" component={RedirectLink} >

    </Route> 

    <Route exact path="/search/:query" component={Search} >

</Route> 

<Route exact path="/games/:type/:page?/:num?" component={Games} >

</Route> 

<Route exact path="/page/:pagename" component={TextPage} >

</Route> 

<Route exact path="/shops" component={ShopsPage} >

</Route> 

<Route exact path="*" component={Errorpage} >

</Route> 







    </Switch>
  
</Router>

<Footer />



    </div>
 
  );
}

export default App;
