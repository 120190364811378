import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect,useMemo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
    
  } from "react-router-dom";
  import Category from '../helpers/GameCategorys'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCoffee,faSearch,faBomb,faCrosshairs,faFutbol,faChessRook,faBolt} from '@fortawesome/free-solid-svg-icons'
  import {Sendreq} from '../req'
  import HomePageHeder from '../helpers/HomePageHeder'
  import MainGameShopSession from '../helpers/GameShopSession/MainGameShopSession';
  import FirstGames from '../helpers/HomePageFirstGames/FirstGames';
  import News from '../helpers/News/MainNews'
  import About from '../helpers/HomePageAbout/About'
  import {Helmet} from "react-helmet";


export default function (props){
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const [Categorey,SetCategorey]=useState([]);
    const [Pick,SetPick]=useState(1);
    const [ref,setref]=useState(0)
    const history = useHistory()
    function SetPickFN(pick1){
        SetPick(pick1)

    }
  
    useEffect(async()=>{

        document.title="Elden Ring | Cd Key Compare Prices";

        const getcategories=await Sendreq(REACT_APP_API_URL+'apicall/getcategories',{});
        SetCategorey(getcategories);
      


    },[])








    return(
        <>
     
   
     <HomePageHeder  />
  

<div className='bg'>
        <Container>
            <div className='FirstGameSession'>
                <div className='UserPick'><span onClick={()=>{SetPickFN(1)}} className={Pick===1 ? 'bold' : "normal"}  >Most Views |</span > <span onClick={()=>{SetPickFN(2)}} className={Pick===2  ? 'bold' : "normal"} >New Games |</span> <span onClick={()=>{SetPickFN(3)}}  className={Pick===3 ? 'bold' : "normal"} >Upcoming Games</span></div>
                    <FirstGames  choose={Pick}/>
            </div>
        </Container>

        </div>
        <Container>
            <Row>
            <Category Categorey={5} icon1={faCrosshairs} type={"Ganer"} categoryname="FPS" />
            <Category Categorey={1001} icon1={faBomb} type={"Ganer"} categoryname="Action" /> 
            <Category Categorey={11} icon1={faChessRook} type={"Ganer"} categoryname="Strategy" />
            <Category Categorey={13} icon1={faBolt} type={"Ganer"} categoryname="Simulator" />
          
            <Category Categorey={14} icon1={faFutbol}  type={"Ganer"} categoryname="Sport" />
          <Category Categorey={12} icon1={faBomb} type={"Ganer"} categoryname="Role playing" /> 
            
            
       
            </Row>

     
        </Container>

   
        
        <MainGameShopSession />

      {/*   <News /> */}
        <About />
        </>
    );

}