import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {Sendreq} from '../../req'
import {
    BrowserRouter as Router,
    Link
   
  } from "react-router-dom";
import FormRange from 'react-bootstrap/esm/FormRange';


export default function (props){
    const {GameNameDEFAULT,GameNameOFFICAL,Gameid,MinPrice,coverimage,defaultcoverimage,moblieSlider}=props
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const baseurl=String(REACT_APP_BASE_FOR_IMAGES);
    const [image,Setimage]=useState(coverimage !==null ? coverimage : defaultcoverimage)
    const slugtitle=typeof GameNameOFFICAL === "undefined" ? GameNameDEFAULT.replace(/[^a-z0-9]/gi, '-').toLowerCase() : GameNameOFFICAL.replace(/[^a-z0-9]/gi, '-').toLowerCase();



   return(

        <Col className='spmd2' xs={6} md={2}><Link to={"/game/"+Gameid+"/"+slugtitle}><img className='FirstSessionCubeimg' src={baseurl+image} /><div className='FirstSessionText'><span className='FirstSessionPrice'>From - <b>{MinPrice} $</b></span><span className='FirstSessionTitle'>{GameNameOFFICAL === null ? GameNameDEFAULT : GameNameOFFICAL }</span></div> </Link> </Col>
   
   );

    

}