import 'bootstrap/dist/css/bootstrap.min.css';

import { Button,Row,Container,Navbar,Nav,Tooltip,Col,Form} from 'react-bootstrap';
import { useState,useEffect,useRef  } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation,
    useParams 
  } from "react-router-dom";
 


  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCoffee,faSearch,faBomb } from '@fortawesome/free-solid-svg-icons'
  import BrowsHeader from '../helpers/BrowseGames/BrowseHeader';
  import {Sendreq} from '../req'
  import ThreeGameList from '../helpers/HomePageFirstGames/ThreeGameList';
  import PaginatedItems from '../helpers/Paginates'
  import {useNavigate} from 'react-router-dom';
  import Pagination from 'react-responsive-pagination';
 

export default function (props){
  
    const history = useHistory();
    const [GameList,SetGameList]=useState([])
    let { type,page,num } = useParams();
    const [ItemNum,SetItemNum]=useState(0);
    const {REACT_APP_CATEGORY_LIMIT_PRODUCT,REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
     const [PageNum,SetPageNum]=useState(num===undefined ? 1 : num) 
     const [currentPage, setCurrentPage] = useState(num===undefined ? 1 : num);
    const [redirect,Setredirect]=useState(false)
    const [Genre,SetGenre]=useState([]);
    const [UserGenreSelect,SetUserGenreSelect]=useState("")
    useEffect(async()=>{
        const Genre1=await Sendreq(REACT_APP_API_URL+'apicall/Genre',{req:"asd"});
        SetGenre([{id: 0, mainid: 0, genrename: 'All'},...Genre1.data])
      },[])

   useEffect(async()=>{
        var pageToSend;

        if(page===undefined || num===undefined){
          pageToSend=1
          document.title=" COMPKEYS "+type + " Games Page | 1";
  
          ///alert("hen amud!")
        }
        else{
          pageToSend=num
          document.title=" COMPKEYS "+type + " Games Page | "+num;
  
        }
      /*   SetPageNum(pageToSend) */
      
        
    
          const GamesByZaner=await Sendreq(REACT_APP_API_URL+'apicall/gamesbyGenreidLimit',{Limit:15,GenreName:type,pageNum:pageToSend});
          SetGameList(GamesByZaner.data)
      /*     if(pageToSend !==1){
            history.push('/games/'+type+'/page/'+pageToSend)
          }
          else{
            history.push('/games/'+type)

          } */

    

      },[type,num]) 

      useEffect(async()=>{
            
          const num1=await Sendreq(REACT_APP_API_URL+'apicall/GenreNumOfPages',{GenreName:type});
          SetItemNum(num1.data[0].num)
          console.log(num1.data[0].num)
         
         
  
      },[type])


  function ChaneRdio(e){
    
    ////SetUserGenreSelect(e.target.value)
    history.push('/games/'+e.target.value)


  }

  function SetPageNUMFN(CurrectPage){
    let url
    if(CurrectPage !==1){
      url="/games/"+type+'/page/'+CurrectPage
    }
    else{
      url='/games/'+type
    }
   
    history.push(url)
    SetPageNum(CurrectPage)
  } 

  function currectNum(){
    
   // alert(num)
    if(num===undefined)
        return 1
    else
      return num

  }

  useEffect(()=>{
    ///SetPageNum(num===undefined ? 1 : num) 
    setCurrentPage(num===undefined ? 1 : num)
    
  },[num])
  useEffect(()=>{
    
    ///alert(currentPage)
  },[currentPage])

  function handlePageChange(page) {
    setCurrentPage(page);
    SetPageNUMFN(page)
    // ... do something with `page`
  }


    return(
        <div className='BrowsGames'>
            <BrowsHeader type={type} />

        <Container>
            <Row className='mt-2'>
                <Col md={2}>
                    <div className='Genrelist'>
                        <h1>Genre List</h1>
 
                    <Form >
      
  {Genre.map((item,index) => (
  
    <div  key={`default-${item.genrename}`} className="mb-3 GenreChoose">
        
      <Form.Check 
        type={"radio"}
        index={index}
        id={item.genrename}
        label={item.genrename}
        value={item.genrename}
        
        checked={type===item.genrename ? true : false}
        onChange={ChaneRdio}
      />

  
    </div>

  ))}
</Form>


                    </div>
                </Col>
                <Col md={10}>
                    <div className='GenreGames'>
                    <Row>
    
    
    {GameList && GameList.map((item,index)=>
            <ThreeGameList key={Math.floor(Math.random() * 2000000)} {...item} />




            )}


</Row>
                        

                    </div>
                      <div className='pagination-session'>

 
        <Pagination 
      total={Math.ceil(parseInt(ItemNum)/parseInt(REACT_APP_CATEGORY_LIMIT_PRODUCT))}
      current={parseInt(currentPage)}
      onPageChange={page => handlePageChange(page)}
    />

                      </div>

                </Col>
            </Row>
           

        </Container>
        
        </div>
    );

}