import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    useParams 
  } from "react-router-dom";
  import {Sendreq} from '../../req'
  

export default function (props){
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    let { linkid } = useParams();
    useEffect(async ()=>{
        const link=await Sendreq(REACT_APP_API_URL+'apicall/singlelink',{linkid:linkid});
        setTimeout(async () => {

            document.title="COMPKEYS - Redirect";
            if(link.data.length>0){
                const req=await Sendreq(REACT_APP_API_URL+'apicall/clicklinkinc',{linkid:linkid});
               
                
                window.location.href = link.data[0]["productlink"];
            }

            else{
                alert("game not found");
            }
            
        }, 2000);
    },[])
    return(
        <>
        <Container>
        <div className='LinkPage'>
            <p>Your Redirected ...</p>
            <br />
            <img src='/loading.gif'/>
        </div>
        </Container>
        </>
    );

}