import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faSearch,faBomb } from '@fortawesome/free-solid-svg-icons'
import {Sendreq} from '../req'
import SingleGame from './SingleGameHome'



export default function (props){
  const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
   
const {GameNameDEFAULT,GameNameOFFICAL,Gameid,MinPrice,coverimage,defaultcoverimage,SearchForm}=props
    const baseurl=String(REACT_APP_BASE_FOR_IMAGES)
   const gametitle=typeof GameNameOFFICAL === "undefined" ? GameNameDEFAULT.replace(/[^a-z0-9]/gi, '-').toLowerCase() : GameNameOFFICAL.replace(/[^a-z0-9]/gi, '-').toLowerCase()

    useEffect(async()=>{
        
      
       

    },[])
    


    return(
      <>
        <Link to={"game/"+Gameid+"/"+gametitle}>
        <div className='GameRowSearch'>
      <Row>
      <Col md={2} xs={2}><img className='img-fluid' src={baseurl+coverimage} /></Col>
      <Col md={5} xs={5}><span>{GameNameOFFICAL}</span></Col>
      <Col md={3} xs={3}><span>${MinPrice}</span></Col>
      </Row>


      </div>
      </Link>
      
      </>

    );

}