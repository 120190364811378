import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
    
  } from "react-router-dom";
  import MainGameShopSession from '../helpers/GameShopSession/MainGameShopSession';



export default function (props){
    








    return(
  <div className='page404'>
      <Container>
      <h1>Its not you , its us 😔</h1>
      
      <div className='img404'>
          <img className='img-fluid' src='/3828537.png'></img>

      </div>
      </Container>
      
  </div>
    );

}