import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faSearch,faBomb,faGun } from '@fortawesome/free-solid-svg-icons'
import {Sendreq} from '../req'
import HomePageCategorysRow from '../helpers/HomePageProductRow/HomePageCategorysRow'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



export default function (props){
    const {categoryname,icon1,Categorey}=props;
    const [GameList,SetGameList]=useState([]);
    const [Loading,SetLoading]=useState(true)
    const {REACT_APP_API_URL}=process.env

    useEffect(async()=>{
        SetLoading(true)
        if(props.type==='Category'){
        const getcategoriesitems=await Sendreq(REACT_APP_API_URL+'apicall/gamesbyCategoryid',{Categoryid:Categorey});
        SetGameList(getcategoriesitems.data);
        SetLoading(false)
        }
        else{
            const getcategoriesitems=await Sendreq(REACT_APP_API_URL+'apicall/gamesbyGenreid',{Genreid:Categorey});
            SetGameList(getcategoriesitems.data);
            SetLoading(false)
          
            
        }
       

    },[])

    const LoadingFN=()=>{
        return(

           
            <>
            <Col className='col-4'><Skeleton height={100} /></Col>
            <Col className='col-8'>
                <Skeleton  />
                <Skeleton width={60}/>
            
            </Col>
   
            <Col className='col-4'><Skeleton height={100} /></Col>
            <Col className='col-8'><Skeleton  />
            <Skeleton width={60}/>
            </Col>
         
            <Col className='col-4'><Skeleton height={100} /></Col>
            <Col className='col-8'><Skeleton  />
            <Skeleton width={60}/>
            
            </Col>
        
            <Col className='col-4'><Skeleton height={100} />
            
            </Col>
            <Col className='col-8'><Skeleton  />
            <Skeleton width={60}/>
            </Col>
         
            <Col className='col-4'><Skeleton height={100} /></Col>
            <Col className='col-8'><Skeleton  />
            <Skeleton width={60}/>
            </Col>
          
            </>
        

        );
    }

    const ProductsDisaplay=()=>{
        return (
            <>
            {GameList && GameList.map((item,index)=>

                <HomePageCategorysRow key={Math.floor(Math.random() * 2000000)} {...item} />
            )}
            </>

        );
    }
    


    return(
        <Col md={6} xs={12}>
        <div className='CategorySession'>
        <h2 className='categorytitle'> <FontAwesomeIcon className='categoryIcons' icon={icon1} /> {categoryname} {/* Game CD Keys */}</h2>
        
        <Row>
          
            
            {Loading ? <LoadingFN /> : <ProductsDisaplay /> }

            <Link to={"/games/"+categoryname} className='ViewMore'>View More</Link>

        </Row>
       
        </div>
        </Col>
    );

}