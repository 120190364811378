import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";

export default function (props){
    
    const{REACT_APP_BASE_FOR_IMAGES}=process.env
    const baseurl=String(REACT_APP_BASE_FOR_IMAGES);
    const {afflink,clicks,coverimage,defaultcoverimage,defaulttitle,linkid,officialtitle,productprice,productregion,usingplatform,index}=props;
const imgbackround={
  backgroundImage: "url(" + baseurl+coverimage + ")"
}
   return(
   
   
<Link to={"redirect/"+linkid} target="_blank">
                        <Row className='SingleProductRow'>

                         <Col md={12} xs={12}>
                             <Row className='CenterAlign'>
                             <Col md={2} className="imgcol" xs={2}>
                                 <div className='ShopBrandSingleProduct'>
                                 
                                 <img className='img-fluid' src={baseurl+coverimage} />

                                 </div>
                             </Col>
                             <Col md={4} xs={4}>
                             <div className='ShopBrandSingleProduct'>
                             <span>{defaulttitle }</span>
                             </div>
                             </Col>
          
                                <Col md={2} className="" xs={2}>
                              
                                    
                          
                                    <img className='img-fluid' src={baseurl+"//images/platformlogo/"+usingplatform.toLowerCase().replace(/\s/g, '')+".png"} />
                                  
                                </Col>
                                <Col md={2} xs={2}>
                                <div className='ShopBrandSingleProduct'>
                                <span>{productregion}</span>
                                </div>
                         
                                </Col>

                                <Col md={2} xs={2}>
                                <div className='ShopBrandSingleProduct'>
                                    
                                <span>${productprice}</span>
                                </div>
                         
                                </Col>
                            </Row>
                            </Col>
                            </Row>
                            </Link>
     
  

   );

    

}