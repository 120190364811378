import {react,useEffect,useState} from 'react';
import axios from 'axios'


export async function Sendreq(Url,dataArray){
    let data;
  
    
       return new Promise((resolve,reject)=>{

            var bodyFormData = new FormData()
          //  bodyFormData.append("token", JSON.stringify(dataArray))
           bodyFormData.append("data", dataArray)
            axios({
                method: 'post',
                url: Url,////"https://bengurion.wdev.co.il/api/admin/management/returnclosingdays",
                data: dataArray,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                
                
               // headers: {'Accept': 'application/json',"Auth": localStorage.getItem("token")}
            })
            .then(function (response) {
                console.log(response)

                resolve(response);
              


            }).catch((error) => {


               //// window.location.href = "http://localhost:3000/404";
                resolve(false);

        })



          });




    
}
