import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Col,Nav,NavDropdown} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
    
  } from "react-router-dom";
  import ShopsList from '../helpers/GameShopSession/MainGameShopSession'
  import PageHeader from '../helpers/BrowseGames/BrowseHeader'





export default function (props){

    useEffect(()=>{

        document.title="COMPKEYS - Shops"
    },[])
    



    return(
        <div className='ShopsPage'>
            <PageHeader type={"Supported Stores"} />

            <Container>
            <Row className='CenterFlex'>
                <Col md={9}>
                
            
            <div className='TextContect mt2'>
                <h2>The stores we work with</h2>  
                <p>
                We currently work with (3) stores (htrk, gamivo, kinguin) and we do our best to cooperate with other stores, to bring you the cheapest price for any game you choose! Starting in 2020, we continue to develop our system to achieve the cheapest prices in the market.

                </p>  
            </div>
            </Col>
            </Row>

          


            </Container>
            <ShopsList />

        </div>
       
    );

}