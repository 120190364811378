import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Col,Nav,NavDropdown} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
    
  } from "react-router-dom";
  import MainGameShopSession from '../helpers/GameShopSession/MainGameShopSession';



export default function (props){
    



    return(
        <footer>
    
            <div className='black-Footer'>
                <Container>
                    <Row>
                        <Col md={3}>
                            
                            <h1 style={{fontWeight:"bold"}}>COMPKEYS</h1>
                            <p>all rights reserved 2022 Ⓒ</p>
                        </Col>

                        <Col md={4}>
                         <Row>
                                <h2 className='Genersh1'>Geners</h2>
                                <Col md={6}>
                                <ul className='footerGeners'>
                                    <li>FPS</li>
                                    <li>Action</li>
                                    <li>WarFare</li>
                                    <li>Music</li>
                                    <li>Simulator</li>


                                </ul>
                                
                                </Col>
                             
                                
                                <Col md={6}>
                                <ul  className='footerGeners'>
                                    <li>FPS</li>
                                    <li>Action</li>
                                    <li>WarFare</li>
                                    <li>Music</li>
                                    <li>Simulator</li>


                                </ul>
                                </Col>
                                </Row>
                        </Col>
                        <Col md={2}>
                            <h2>Social Media</h2>
                            <ul className='Footer-SocialMedia'>
                                <li><img src="/fb.svg" alt="Our FaceBook" /></li>
                                <li><img src="/insta.svg" alt="Our Instegram" /></li>
                                <li><img src="/twitter.svg" alt="Our Twitter" /></li>
                            </ul>
                        </Col>

                        <Col md={3}>
                            <ul className='Footer-Menu'>
                                <h2>Menu</h2>
                                <ul>
                                    <li>Terms of use</li>
                                    <li>Shop Owners</li>
                                    <li>Contact Us</li>
                                </ul>

                            </ul>

                        </Col>
                    </Row>
                </Container>

            </div>

        </footer>
    );

}