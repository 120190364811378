import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import DOMPurify from 'dompurify'
import {
    BrowserRouter as Router,
    useParams 
  } from "react-router-dom";
  import {Sendreq} from '../../req';
  import {faGlobe } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import ClickedGamesByShop from './ClickedGamesByShop';

export default function (props){
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const [singleshop,Setsingleshop]=useState([]);
    const [LastClicks,SetLastClicks]=useState([]);
    const siteurl=String(REACT_APP_BASE_FOR_IMAGES)
    let { shopid,shopname } = useParams();

    useEffect(async()=>{
        const singleshop=await Sendreq(REACT_APP_API_URL+'apicall/singleshop',{shopid:shopid});
        Setsingleshop(singleshop.data[0]);
        const links=await Sendreq(REACT_APP_API_URL+'apicall/GetLstClickedByShop',{Shopid:shopid});
        SetLastClicks(links.data);
        document.title="COMPKEYS - Shop | "+shopname;
    },[])



    return(
        <>
      
            <div className='Browserslider'>
                <Container>
                <h1>About {shopname} </h1>
                </Container>

            </div>


    <div className='singleShopPage'>
    <Container>
        <Row>
        <Col md={2}>
            <div className='sideshop'>
                <div className='shopImgBlock'>
                <img className='shopsingleimage' src={siteurl+singleshop.sitelogofull}></img>
                </div>

                <a className='centertext' href={singleshop.siteadress}><FontAwesomeIcon icon={faGlobe} /> Link to {singleshop.shopname}</a>
                </div>

        </Col>
        <Col md={8}>
        
           <div className='AboutContect'>
               <h2>About {shopname} CdKey Shop</h2>
               <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(singleshop.Descriptions)}}></p>

           </div>

           <h2 style={{"marginTop":"1.3em","marginBottom":"1.6em","color":"#c7c7c7","fontWeight":600}}>Last popular links from {shopname}</h2>
           <Row className='CenterTextAL'>
               <Col md={2}>Image</Col>
               <Col md={4}>Title</Col>
               <Col md={2}>Platform</Col>
               <Col md={2}>Region</Col>
               <Col md={2}>Price</Col>
           </Row>
           <div  className='shopcontectBlockLinks'>
               {LastClicks && LastClicks.map((item,index)=>
               <ClickedGamesByShop {...item} />

               
               )}


           </div>


        </Col>
        </Row>
    </Container>
    </div>
    </>


    );
}