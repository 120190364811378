import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";

export default function (props){
  const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    const baseurl=String(REACT_APP_BASE_FOR_IMAGES);
    const {GameNameDEFAULT,GameNameOFFICAL,Gameid,MinPrice,coverimage,defaultcoverimage,index,DiscountPrecent}=props;
const imgbackround={
  backgroundImage: "url(" + baseurl+coverimage + ")"
}
const slugtitle=typeof GameNameOFFICAL === "undefined" ? GameNameDEFAULT.replace(/[^a-z0-9]/gi, '-').toLowerCase() : GameNameOFFICAL.replace(/[^a-z0-9]/gi, '-').toLowerCase();

   return(
   
    <Link to={"game/"+Gameid+"/"+slugtitle}>
 
    <Row className='SingleMultiGameV2'>
      
      <Col  className='col-3 Image-Col'>
        <img src={baseurl+coverimage} className="ProductImage"></img>

      </Col>
      <Col  md={7} xs={6} className='col-md-7 col-xs-7'>
      <br />
        <span className='SingleTitleV2'>{GameNameOFFICAL}</span>
      
      
       
      </Col>
      <Col md={2} xs={3} className='col-md-2 col-xs-2'>
      <br />
      <span className='HomePrice'>${MinPrice}</span>
    
     {DiscountPrecent >= 20 && <p className='presentOff'>-{DiscountPrecent}%</p> } 
      </Col>
      
  </Row>

  </Link>
     
  

   );

    

}