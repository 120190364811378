import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Row,Container,Navbar,Nav,NavDropdown,Col} from 'react-bootstrap';
import { useState,useEffect,useMemo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useParams
    
  } from "react-router-dom";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCoffee,faSearch,faBomb,faCrosshairs,faFutbol,faChessRook,faBolt} from '@fortawesome/free-solid-svg-icons'
  import {Sendreq} from '../req'
  import ThreeGameList from '../helpers/HomePageFirstGames/ThreeGameList';
  import DOMPurify from 'dompurify'


export default function (props){
  const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env

    const [PageObject,SetPageObject]=useState([])


    let { pagename } = useParams();

    useEffect(async()=>{

      const page=await Sendreq(REACT_APP_API_URL+'apicall/pagecontext',{PageName:pagename});
      SetPageObject(page.data[0]) 

      document.title="COMPKEYS | "+pagename         
    
    
    
      },[])
  


    return(

        <div className='PageContext'>
       <Container>
        <Row className='CenterFlex'>
            <Col md={8}>
                <div className='TextContect'>
               <h2>{PageObject.pagetitle}</h2> 
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(PageObject.pagecontext)}}></p>
 


                </div>
            </Col>
        </Row>   
        </Container>    

       </div>
       
 
    );

}