import 'bootstrap/dist/css/bootstrap.min.css';
import { Row,Container,Col} from 'react-bootstrap';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Link
   
    
  } from "react-router-dom";

  import {Sendreq} from '../../req'

export default function (props){
    const [Shops,SetShops]=useState([]);
    const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
    useEffect(async()=>{
        const getShops=await Sendreq(REACT_APP_API_URL+'apicall/allshops',{});
        SetShops(getShops.data);
    },[])

    var siteurl=String(REACT_APP_BASE_FOR_IMAGES);
    return(
        <div className='ShopSession'>
            <Container>
                <Row>
                <h2>CD Keys Stores</h2>
           {Shops && Shops.map((item,index)=>
                 <Col md={3} key={Math.floor(Math.random() * 2000000)}>
                     
                     <Link  to={'/shop/'+item.id+'/'+item.shopname}>
                         <div className='ShowContect'>
                             
                         <img className='img-fluid' src={siteurl+item.sitelogofull}></img>
                         </div>
                    </Link>
                 
                 
                 </Col>
                 
            
            
            
            )}
                     

                </Row>

            </Container>


        

        </div>
    );

}