import 'bootstrap/dist/css/bootstrap.min.css';
import { useState,useEffect,useLayoutEffect  } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory
  } from "react-router-dom";
  import { Button,Row,Container,Navbar,Nav,NavDropdown,Offcanvas,Form,FormControl,InputGroup,Col} from 'react-bootstrap';
  import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faCommentsDollar,faSearch } from '@fortawesome/free-solid-svg-icons'
import {Sendreq} from '../req'
import Topgameslider from '../helpers/topgameslider'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CountUp from 'react-countup';

export default function (props){
  const [Topfivearray,SetTopfivearray]=useState([]);
  const [ref,setref]=useState(0)
  const [refresh, setRefresh] = useState(true) 
  const [loading,SetLoading]=useState(true);
  const [GameCount,SetGameCount]=useState(0);
 

  const [cameByBackButton, setCameByBackButton] = useState(false)
  const{REACT_APP_BASE_FOR_IMAGES,REACT_APP_API_URL}=process.env
  useEffect(async()=>{
    SetLoading(true);
  
      const topfive=await Sendreq(REACT_APP_API_URL+'apicall/Topfive',{});
      console.log(topfive.data);
      SetTopfivearray(topfive.data)
      console.log("SDFsdf")
      const GameCount=await Sendreq(REACT_APP_API_URL+'apicall/GameCount',{});
    
      SetGameCount(GameCount.data[0]['COUNT'])
      SetLoading(false);

  
  

  },[])



const LoadingFN=()=>{
  return(

<div className='top-loading'>
  <Row>
<Col  md={2} xs={6} ><Skeleton  height={250} />

<Skeleton  height={40} />

</Col>

<Col  md={2} xs={6} ><Skeleton  height={250} />

<Skeleton  height={40} />

</Col>

<Col  md={2} xs={6} ><Skeleton  height={250} />

<Skeleton  height={40} />

</Col>

<Col  md={2} xs={6} ><Skeleton  height={250} />

<Skeleton  height={40} />

</Col>

<Col  md={2} xs={6} ><Skeleton  height={250} />

<Skeleton  height={40} />

</Col>

<Col  md={2} xs={6} ><Skeleton  height={250} />

<Skeleton  height={40} />

</Col>
</Row>
</div>
  );





}

const ShowProducts=()=>{

  return(
  
    Topfivearray && Topfivearray.map((item,index)=>
   
  
      <Col className='MoblieColSliderProducts' key={Math.floor(Math.random() * 2000000)} md={2} xs={6} ><Topgameslider key={Math.floor(Math.random() * 2000000000)} {...item} /></Col>
     
     )
   

  );


}

    return(
        <>
      
 
<div className='slider'>
      
   <div className='insideDivs'>
   <div className='HomeHeaderContect'>
     
        <h1>We have currecly <b><CountUp start={13000} duration={1.6} end={GameCount} /></b> games in <p>our DataBase</p></h1>

      </div>
 
   </div>
  
  
</div>


        </>
    );

}